<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ folder }}</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ folder }}</ion-title>
        </ion-toolbar>
      </ion-header>
    
      <div id="container">
		<ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
			<ion-refresher-content></ion-refresher-content>
		</ion-refresher>
         <ion-list>
            <ion-item class="ion-no-padding" v-for="item in posts" v-bind:key='item.id'>
                <div class="post">
                    <div class="post__header">
                        <span class="post__title">{{item.title}}</span><span class="post__date">{{item.created_at}}</span>
						<span v-if="item.pivot.status===0" class="message__unread">
							новое!
						</span>
                    </div>
                    <div class="post__body">
                        <div class="post__author" @click="() => $router.push(getUserPath(item.user))">
                            <img v-bind:src="getUserAvatar(item.user)">
                            <div class="post__username">{{item.user.nickname}}</div>
                            <div class="post__sign">{{item.user.sign}}</div>
                        </div>
                        <div class="post__text" v-html="getRichText(item.text)" @click="() => $router.push(getPostPath(item))" router-link="getPostPath(item)">
                        </div>
                    </div>
					<div class="post__commcount">Комментариев: {{item.comments_count}}</div>
                </div>
            </ion-item>
          </ion-list>
      </div>
    </ion-content>
  </ion-page>
</template>

<style scoped>
ion-menu-button {
  color: var(--ion-color-primary);
}
ion-item{
    --inner-padding-end: 0.5%;
    --padding-start:0.5%;
	padding-bottom: 20px;
}
.post__commcount {
	text-align: right;
}
.message__unread {
	/*font-size: 75%;*/
	margin-left: 1rem;
    background-color: #71ff714a;
}
</style>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonItem, IonList, IonRefresher, IonRefresherContent } from '@ionic/vue';
import { useRoute } from 'vue-router';
import { ref, computed, watch } from 'vue';
import axios from 'axios';
import useDataService from "../dataservice";

export default {
	name: 'Folder',
	components: {
		IonButtons,
		IonContent,
		IonHeader,
		IonMenuButton,
		IonPage,
		IonTitle,
		IonToolbar,
		IonItem,
		IonList,
		IonRefresher,
		IonRefresherContent
	},
	methods: {
		getUserPath: function(user: any){
			return "/user/" + user.name;
		},
		getPostPath: function(item: any){
			if(item.blog_id){
				return "/blog/" + item.blog.name + "/post/" + item.blogpostid;
			} else {
				return "/topic/" + item.id;
			}
		},
		getUserAvatar: function(user: any){
			return user.avatar ? "//i0.beon.fun/" + user.path + "/avatars/" + user.avatar.idx + "." + user.avatar.ext : '';
		},
		getRichText: function(txt: string){
		return txt.replace(/(\s|^):-\)(\s|$)/,'$1<img src="//beon.fun/i/smiles/smile.png">$2')
		.replace(/(\s|^):-\((\s|$)/,'$1<img src="//beon.fun/i/smiles/sad.png">$2')
		.replace(/(\s|^);-\)(\s|$)/,'$1<img src="//beon.fun/i/smiles/wink.png">$2')
		.replace(/(\s|^):-\*(\s|$)/,'$1<img src="//beon.fun/i/smiles/kiss.png">$2')
		.replace(/(\s|^):-D(\s|$)/,'$1<img src="//beon.fun/i/smiles/big-smile.png">$2')
		.replace(/(\s|^):-O(\s|$)/,'$1<img src="//beon.fun/i/smiles/surprised.png">$2')
		.replace(/(\s|^):-P(\s|$)/,'$1<img src="//beon.fun/i/smiles/tongue-sticking-out.png">$2')
		.replace(/(\s|^)X-\((\s|$)/,'$1<img src="//beon.fun/i/smiles/angry.png">$2')
		.replace(/(\s|^)]:-\)(\s|$)/,'$1<img src="//beon.fun/i/smiles/devil.png">$2')
		.replace(/(\s|^)O:-\)(\s|$)/,'$1<img src="//beon.fun/i/smiles/angel.png">$2')
		.replace(/(\s|^):'\((\s|$)/,'$1<img src="//beon.fun/i/smiles/cry.png">$2')
		.replace(/(\s|^):-\[(\s|$)/,'$1<img src="//beon.fun/i/smiles/upset.png">$2')
		.replace(/(\s|^):-\\(\s|$)/,'$1<img src="//beon.fun/i/smiles/confused.png">$2')
		.replace(/(\s|^):-\|(\s|$)/,'$1<img src="//beon.fun/i/smiles/undecided.png">$2')
		.replace(/(\s|^):-\?(\s|$)/,'$1<img src="//beon.fun/i/smiles/thinking.png">$2')
		.replace(/(\s|^);~\)(\s|$)/,'$1<img src="//beon.fun/i/smiles/cunning.png">$2')
		.replace(/(\s|^)\(:\|(\s|$)/,'$1<img src="//beon.fun/i/smiles/tired.png">$2')
		.replace(/(\s|^)8-}(\s|$)/,'$1<img src="//beon.fun/i/smiles/crazy.png">$2')
		.replace(/(\s|^):-\$(\s|$)/,'$1<img src="//beon.fun/i/smiles/shhh.png">$2')
		.replace(/(\s|^)8-\|(\s|$)/,'$1<img src="//beon.fun/i/smiles/shocked.png">$2')
		.replace(/(\s|^)B-\)(\s|$)/,'$1<img src="//beon.fun/i/smiles/sun-glasses.png">$2')
		.replace(/(\s|^):\^\)(\s|$)/,'$1<img src="//beon.fun/i/smiles/turn-red.png">$2')
		.replace(/(\s|^)=\^B(\s|$)/,'$1<img src="//beon.fun/i/smiles/thumbs-up.png">$2')
		.replace(/(\s|^)=,B(\s|$)/,'$1<img src="//beon.fun/i/smiles/thumbs-down.png">$2')
		.replace(/^&gt; (.*)(\s|$)/gm,'<p class="quotation">$1</p>')
		.replace(/\r\n/g, '<br>')
		.replace(/(\s|^)(https?:\/\/[a-zA-Z0-9а-яА-ЯёЁ./=+&?%-_#]*)(\s|$)/,'$1<a href="$2" target="_blank">$2</a>$3')
		.replace(/\[B\](.*?)\[\/B\]/gm,'<strong>$1</strong>')
		.replace(/\[I\](.*?)\[\/I\]/gm,'<i>$1</i>')
		.replace(/\[U\](.*?)\[\/U\]/gm,'<u>$1</u>')
		.replace(/\[S\](.*?)\[\/S\]/gm,'<s>$1</s>')
		.replace(/\[H\](.*?)\[\/H\]/gm,'<span style="font-size: 1.6rem">$1</span>')
		.replace(/\[OFF\](.*?)\[\/OFF\]/gm,'<span style="font-size: 0.7em">$1</span>')
		.replace(/\[CENTER\](.*?)\[\/CENTER\]/gm,'<p style="text-align: center">$1</p>')
		.replace(/\[RIGHT\](.*?)\[\/RIGHT\]/gm,'<p style="text-align: right">$1</p>')
		.replace(/\[JUSTIFY\](.*?)\[\/JUSTIFY\]/gm,'<p style="text-align: justify">$1</p>')
		.replace(/\[SPOILER\].*\[\/SPOILER\]/,"<b>Подробнее...</b>")
		.replace(/\[img-(original|medium|small)-(none|left|right)-([^\s]*?)\]/gm,"<span class='imagewrapper' style='display:inline-block;max-width:410px;'><img src='$3' style='max-width:100%' class='imagefsme'></span>")
		.replace(/\[video-youtube-(.*)?\]/,'<iframe width="100%" height="315" src="https://www.youtube.com/embed/$1"></iframe>')
		.replace(/\[audio-soundcloud-track-([0-9]*)?\]/,'<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/$1&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>')
		.replace(/\[audio-soundcloud-playlist-([0-9]*)?\]/,'<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/$1&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>')
		.replace(/\[audio-yamusic-track-([0-9]*)-([0-9]*)?\]/,'<iframe frameborder="0" style="border:none;width:100%;height:180px;" width="80%" height="180" src="https://music.yandex.ru/iframe/#track/$1/$2"></iframe>')
		.replace(/\[audio-yamusic-album-([0-9]*)?\]/,'<iframe frameborder="0" style="border:none;width:100%;height:450px;" width="80%" height="450" src="https://music.yandex.ru/iframe/#album/$1"></iframe>')
		.replace(/\[audio-spotify-track-(.*)?\]/,'<iframe src="https://open.spotify.com/embed/track/$1" width="100%" height="80" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>')
		.replace(/\[audio-spotify-album-(.*)?\]/,'<iframe src="https://open.spotify.com/embed/album/$1" width="100%" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>')
		.replace(/\[audio-spotify-playlist-(.*)?\]/,'<iframe src="https://open.spotify.com/embed/playlist/$1" width="100%" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>')
		}
	},
  setup() {
    const route = useRoute();
    const folder = "Обсуждения";
	const posts = ref({});
	const { token } = useDataService();
	
	axios.get('https://beon.fun/api/v1/discussions',{
	headers: {
		Authorization: 'Bearer ' + token.value,
	}})
    .then(response => (
	posts.value = response.data
	));
    return { folder, posts }
  }
}
</script>